import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import AddLeadPopup from './components/AddLeadPopup';

const Home = () => {
    const [toggleLeadPopup, setToggleLeadPopup] = useState(0);
    const [designation, setDesignation] = useState();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setDesignation(userData.designation);
            // console.log(userData);
        }
    }, []);

    const closeLeadPopup = () => {
        setToggleLeadPopup(0);
    }
    const reload = () => {

    }

    return (
        <>

            {toggleLeadPopup === 1 ?
                <AddLeadPopup reload={reload} close={closeLeadPopup} />
                : <></>
            }
            <Header />

            <div className="crm-container">
                <div className='dashboard-cards'>
                    <div className='crm-card'>
                        <Link to='/all-users' className='crm-bg-primary'>
                            <i className='fa fa-user'></i>
                        </Link>
                        <h2 className='crm-color-primary'>Users</h2>
                    </div>

                    <div className='crm-card'>
                    <Link to='/all-leads' className='crm-bg-green'>
                        <i className='fa fa-users'></i>
                    </Link>
                    <h2 className='crm-color-green'>All Leads</h2>
                </div>

                </div>                

            </div>


        </>

    );
}

export default Home;