import react, { useEffect, useState } from 'react';
import PopUpLoader from './PopUpLoader';
import axios from 'axios';


const AddLeadPopup = (props) => {
    const [addLeadColumns, setAddLeadColumns] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [loadingToggle, setLoadingToggle] = useState(0);
    const [alertToggle, setAlertToggle] = useState(0);
    const [alertMessage, setAlertMessage] = useState("Lead successfully added");
    const [alertColor, setAlertColor] = useState("crm-color-green");

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [adName, setAdName] = useState("");
    const [platform, setPlatform] = useState("");
    const [site, setSite] = useState("");
    const [message, setMessage] = useState("");
    const [leadGenDate, setLeadGenDate] = useState("");

    const url = window.url;
    const url2 = window.url2;
    const qry = new FormData();

    const fullDate = new Date();

    useEffect(() => {
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);
            // console.log(userData);

            qry.append("emp_id", userData.user_id);

        }
        addLeadColumnsApi();
    }, []);


    const addLeadColumnsApi = () => {
        axios.post(url + "fetch-add-lead-columns.php", qry).then((response) => {
            if (response.data.status == 1) {
                setAddLeadColumns(response.data);
            }
            console.log(response.data);

        }).catch(error => {
            console.log("error");
        });
    }


    const addLeadSubmit = (e) => {
        e.preventDefault();

        setLoadingToggle(1);
        // alert("Successful");

        // console.log(name+phone+email+adName+platform+site+message+leadGenDate);

        const addLeadData = new FormData();
        addLeadData.append("emp_id",userDetails.user_id);
        addLeadData.append("name", name);
        addLeadData.append("phone", phone);
        addLeadData.append("email", email);
        addLeadData.append("ad_name", adName);
        addLeadData.append("platform", platform);
        addLeadData.append("site", site);
        addLeadData.append("message", message);
        addLeadData.append("lead_gen_date", leadGenDate);

        axios.post(url2 + "add-new-lead.php", addLeadData).then(response => {
            // console.log(response.data);

            if (response.data.status == 1) {
                setLoadingToggle(0);
                setAlertMessage(response.data.msg);
                setAlertToggle(1);
                props.reload();

                setTimeout(()=>{
                    props.close();
                },500);
            }           
        }).catch(error => {
            console.log(error);
        })

    }

    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>

                    {loadingToggle == 1 ?
                        <PopUpLoader/>
                        : <></>
                    }

                    {alertToggle == 1?
                        <div className='crm-alert'>
                            <p className={alertColor}> {alertMessage} </p>
                        </div>
                        :<></>
                    }

                    <div className='add-new-head'>
                        <h2> Add New Lead <i className='fa fa-user-plus'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form onSubmit={addLeadSubmit}>
                            <label> Lead Generation Date* </label>
                            <br />
                            <input type='date' max={fullDate.getFullYear()+"-"+String(fullDate.getMonth()+1).padStart(2,'0')+"-"+String(fullDate.getDate()).padStart(2,'0')} className='form-input' value={leadGenDate} onChange={(e) => setLeadGenDate(e.target.value)} required />

                            <label> Name* </label>
                            <br />
                            <input type='text' className='form-input' value={name} onChange={(e) => setName(e.target.value)} placeholder='name' required />

                            <label> Phone* </label>
                            <br />
                            <input type='number' className='form-input' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='phone' required />

                            <label> Email (optional) </label>
                            <br />
                            <input type='email' className='form-input' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='email' />

                            <select required value={adName} onChange={(e) => setAdName(e.target.value)}>
                                <option value=""> Ad Name </option>

                                {addLeadColumns != [] && addLeadColumns.ad_name_data != null ?
                                    addLeadColumns.ad_name_data.map(data => {
                                        return (<>
                                            <option value={data.id}> {data.ad_name} </option>
                                        </>)
                                    })
                                    : <></>
                                }
                            </select>


                            <select required value={platform} onChange={(e) => setPlatform(e.target.value)}>
                                <option value=""> Platform </option>
                                {addLeadColumns != [] && addLeadColumns.ad_platform_data != null ?
                                    addLeadColumns.ad_platform_data.map(data => {
                                        return (<>
                                            <option value={data.id}> {data.platform} </option>
                                        </>)
                                    })
                                    : <></>
                                }
                            </select>


                            {/* <select required value={site} onChange={(e) => setSite(e.target.value)}>
                                <option value=""> Site </option>
                                {addLeadColumns != [] && addLeadColumns.site_data != null ?
                                    addLeadColumns.site_data.map(data => {
                                        return (<>
                                            <option value={data.id}> {data.site_name} </option>
                                        </>)
                                    })
                                    : <></>
                                }
                            </select> */}


                            <label> Message </label>
                            <br />
                            <textarea name="message" placeholder='message (optional)' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>

                            <button type='submit' className='crm-btn-primary'> Add Lead </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddLeadPopup;