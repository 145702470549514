import react, { useEffect, useState } from 'react';
import PopUpLoader from './PopUpLoader';
import axios from 'axios';


const EditLeadDetailsPopup = (props) => {
    const [userDetails, setUserDetails] = useState([]);
    const [loadingToggle, setLoadingToggle] = useState(0);
    const [alertToggle, setAlertToggle] = useState(0);
    const [alertMessage, setAlertMessage] = useState("Client Requirement successfully added");
    const [alertColor, setAlertColor] = useState("crm-color-green");


    const [location, setLocation] = useState("");
    const [clientCategory, setClientCategory] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [size, setSize] = useState("");
    const [budget, setBudget] = useState("");
    const [message, setMessage] = useState("");
    const [availability, setAvailability] = useState(1);

    const url2 = window.url2;
    const qry = new FormData();

    const fullDate = new Date();

    useEffect(() => {
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);
            // console.log(userData);

            qry.append("emp_id", userData.user_id);

        }
    }, []);


    const addLeadDetailsApi = (e) => {
        e.preventDefault();

        setLoadingToggle(1);
        // alert("Successful");

        // console.log(name+phone+email+adName+platform+site+message+leadGenDate);
        // alert(availability);
        const addLeadDetails = new FormData();
        addLeadDetails.append("emp_id", userDetails.user_id);
        addLeadDetails.append("lead_id", props.leadId);
        addLeadDetails.append("location", location);
        addLeadDetails.append("client_category", clientCategory);
        addLeadDetails.append("property_type", propertyType);
        addLeadDetails.append("size", size);
        addLeadDetails.append("budget", budget);
        addLeadDetails.append("message", message);
        addLeadDetails.append("availability", availability);

        axios.post(url2 + "add-lead-details.php", addLeadDetails).then(response => {
            // console.log(response.data);

            if (response.data.status == 1) {
                setLoadingToggle(0);
                setAlertMessage(response.data.msg);
                setAlertToggle(1);
                props.reload();

                setTimeout(() => {
                    props.close();
                }, 1000);
            }
        }).catch(error => {
            console.log(error);
        })

    }


    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>
                    {loadingToggle == 1 ?
                        <PopUpLoader />
                        : <></>
                    }

                    {alertToggle == 1 ?
                        <div className='crm-alert'>
                            <p className={alertColor}> {alertMessage} </p>
                        </div>
                        : <></>
                    }
                    <div className='add-new-head'>
                        <h2> Edit Details <i className='fa fa-address-card'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form onSubmit={addLeadDetailsApi}>

                            <label> Location* </label>
                            <br />
                            <input type='text' className='form-input' value={location} onChange={(e) => setLocation(e.target.value)} placeholder='location' required />

                            <select required value={clientCategory} onChange={(e) => setClientCategory(e.target.value)}>
                                <option value=""> Client Category </option>
                                <option value="investor"> Investor </option>
                                <option value="residential"> Residential </option>
                            </select>

                            <select required value={propertyType} onChange={(e) => setPropertyType(e.target.value)}>
                                <option value=""> Property Type </option>
                                <option value="plot"> Plot </option>
                                <option value="flat"> Flat </option>
                                <option value="floor"> Floor </option>
                                <option value="house"> House </option>
                                <option value="appartment"> Appartment </option>
                            </select>

                            <select required value={size} onChange={(e) => setSize(e.target.value)}>
                                <option value=""> Size/Area </option>

                                {propertyType == "flat" || propertyType == "floor" || propertyType == "appartment" ?
                                    <>
                                        <option value="1RK"> 1RK </option>
                                        <option value="1BHK"> 1BHK </option>
                                        <option value="2BHK"> 2BHK </option>
                                        <option value="3BHK"> 3BHK </option>
                                        <option value="3BHK+1"> 3BHK+1 </option>
                                        <option value="4BHK"> 4BHK </option>
                                        <option value="4BHK+1"> 4BHK+1 </option>
                                    </>
                                    : <></>
                                }

                                {propertyType == "plot" || propertyType == "house" ?
                                    <>
                                        <option value="30sqYd"> 30 sqYd </option>
                                        <option value="50sqYd"> 50 sqYd </option>
                                        <option value="60sqYd"> 60 sqYd </option>
                                        <option value="65sqYd"> 65 sqYd </option>
                                        <option value="70sqYd"> 70 sqYd </option>
                                        <option value="80sqYd"> 80 sqYd </option>
                                        <option value="100sqYd"> 100 sqYd </option>
                                        <option value="150sqYd"> 150 sqYd </option>
                                        <option value="200sqYd"> 200 sqYd </option>
                                        <option value="250sqYd"> 250 sqYd </option>
                                        <option value="300sqYd"> 300 sqYd </option>
                                        <option value="350sqYd"> 350 sqYd </option>
                                        <option value="400sqYd"> 400 sqYd </option>
                                        <option value="450sqYd"> 450 sqYd </option>
                                        <option value="500sqYd"> 500 sqYd </option>
                                        <option value="500s+qYd"> 500+ sqYd </option>
                                    </> : <></>
                                }
                            </select>


                            <label> Budget* </label>
                            <br />
                            <input type='number' className='form-input' value={budget} onChange={(e) => setBudget(e.target.value)} placeholder='budget' required />

                            <label> Message </label>
                            <br />
                            <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder='message (optional)'></textarea>

                            <div className='requirement_availability'>
                                <span> Out of Inventory (check if yes)</span>
                                <input type='checkbox' onChange={()=>availability==0?setAvailability(1):setAvailability(0)}/>
                            </div>
                            <br />
                            <button type='submit' className='crm-btn-primary'> Submit </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditLeadDetailsPopup;