import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import AllUsers from "./AllUsers";
import Leads from "./Leads";
import ViewLeadDetails from "./ViewLeadDetails";
import NewLeads from "./NewLeads";
import NewVisits from "./NewVisits";
import ResponseTags from "./ResponseTags";
import AllSource from "./AllSource";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/all-users" element={<AllUsers />} exact />
        <Route path="/all-leads" element={<Leads />} exact />
        <Route path="/new-leads" element={<NewLeads />} exact />
        <Route path="/new-visits" element={<NewVisits />} exact />
        <Route path="/response-tags" element={<ResponseTags/>} exact />
        <Route path="/all-source" element={<AllSource/>} exact />
        <Route path="/view-lead-details/:leadId" element={<ViewLeadDetails />} exact />
        <Route path="/view-lead-details/:leadId/:visit" element={<ViewLeadDetails />} exact />
        <Route path="/reset-password" element={<ResetPassword />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
