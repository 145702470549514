import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import AddUserPopup from './components/AddUserPopup';
import TableLoader from './components/TableLoader';
import axios from 'axios';
import AddSourcePopup from './components/AddSourcePopup';


const AllSource = () => {

    const [toggleSourcePopup, setToggleSourcePopup] = useState(0);
    const [sourceData, setSourceData] = useState([]);

    const [userDetails, setUserDetails] = useState([]);
    const [tableLoader, setTableLoader] = useState(0);

    const [filterData, setFilterData] = useState([]);

    const url = window.url;
    const qry = new FormData();

    const [check, setCheck] = useState(0);
    const navigate = useNavigate();

    const checkbox = useRef();

    useEffect(() => {
        setTableLoader(1);
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);

            if (userData.designation == "A") {

            }
            else {
                navigate("/");
            }
            qry.append("emp_id", userData.user_id);
        }

        fetchUsersApi();
    }, []);

    const reload = () => {
        qry.append("emp_id", userDetails.user_id);
        fetchUsersApi();
    }

    const fetchUsersApi = () => {
        setTableLoader(1);
        axios.post(url + "fetch-all-source.php", qry).then((response) => {
            if (response.data.status == 1) {
                setSourceData(response.data.data);
                setFilterData(response.data.data);
            }
            // console.log(response.data);
            setTableLoader(0);
        }).catch(error => {
            console.log("error");
        });
    }



    const closeUserPopup = () => {
        setToggleSourcePopup(0);
    }


    var i = 0;
    const index = () => {
        i = i + 1;
    }

    const toggleStatus = (id, e) => {

        var confirm = window.confirm("Are you sure ?");
        if (confirm == 1) {
            setTableLoader(1);
            qry.append("emp_id", userDetails.user_id);
            qry.append("id", id);
            qry.append("status", e);
            axios.post(url + "change-source-status.php", qry).then((response) => {
                if (response.data.status == 1) {
                    reload();
                }
                alert(response.data.msg);
                // console.log(response.data);
                setTableLoader(0);
            }).catch(error => {
                console.log("error");
            });
        }
        else {
            setTableLoader(0);
        }
    }
    return (
        <>

            {toggleSourcePopup === 1 ?
                <AddSourcePopup reload={reload} close={closeUserPopup} />
                : <></>
            }

            <Header />

            <div className='container-head'>
                <div>
                    <h2> Sources </h2>
                </div>

                <div>

                    {userDetails.designation == "A" ?
                        <button className='crm-btn-primary' onClick={() => { setToggleSourcePopup(1) }}>  New <i className='fa fa-tag'></i> </button>
                        : <></>
                    }
                </div>

            </div>

            <div className='crm-container'>
                {tableLoader == 1 ?

                    <TableLoader />
                    :
                    <>
                        <div className='crm-table visible-hide'>

                            <table>
                                <thead>
                                    <tr> <th> S No</th> <th> Source Name </th> <th> Status </th> <th> Action </th><th></th></tr>
                                </thead>

                                <tbody>
                                    {
                                        filterData.length != 0 ?
                                            <>
                                                {filterData.map((data) => {
                                                    index();
                                                    return (
                                                        <tr><td> {i}</td> <td>{data.platform}</td> <td> <button onClick={() => toggleStatus(data.id, data.status == 1 ? "0" : data.status == 0 ? "1" : "")} className={data.status == 1 ? "crm-btn-green" : data.status == 0 ? "crm-btn-red" : ""}>{data.status == 1 ? "Active" : data.status == 0 ? "InActive" : ""}</button> </td>  <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td><td><button onClick={() => toggleStatus(data.id, 2)} className="delete-button">{<i className='fa fa-trash'></i>}</button></td></tr>
                                                    );
                                                })
                                                }
                                            </>
                                            : <><tr><td>Empty</td></tr></>
                                    }
                                </tbody>
                            </table>
                        </div>


                        <div className='crm-phone-table hide-visible'>
                            <p style={{ "display": "none" }}>{i = 0}</p>

                            {
                                filterData.length != 0 ?
                                    <>
                                        {filterData.map((data) => {
                                            index();
                                            return (
                                                <>
                                                    <div className='phone-table-item'>
                                                        <div className='table-item-left'>
                                                            <h2> {i} </h2>
                                                        </div>
                                                        <div className='table-item-right'>
                                                            <div>
                                                                <h2>{data.platform}</h2>
                                                                <button onClick={() => toggleStatus(data.id, data.status == 1 ? "0" : data.status == 0 ? "1" : "")} className={data.status == 1 ? "crm-btn-green" : data.status == 0 ? "crm-btn-red" : ""}>{data.status == 1 ? "Active" : data.status == 0 ? "InActive" : ""}</button>
                                                                <button onClick={() => toggleStatus(data.id, 2)} className="delete-button">{<i className='fa fa-trash'></i>}</button>
                                                            </div>
                                                            <div>
                                                                <Link to={'/view-lead-details/' + data.id}>View </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })
                                        }
                                    </>
                                    : <><tr><td>Empty</td></tr></>
                            }


                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default AllSource;