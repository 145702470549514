import react, { useEffect, useState } from 'react';
import PopUpLoader from './PopUpLoader';
import axios from 'axios';


const AddUserPopup = (props) => {
    const [addUserColumns, setAddUserColumns] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [loadingToggle, setLoadingToggle] = useState(0);
    const [alertToggle, setAlertToggle] = useState(0);
    const [alertMessage, setAlertMessage] = useState("Lead successfully added");
    const [alertColor, setAlertColor] = useState("crm-color-red");

    const [alert, setalert] = useState(0);
    const [alertMsg, setAlertMsg] = useState("");
    const [disable, setDisable] = useState(0);


    const [availability, setAvailability] = useState(0);
    const [userAvailablity, setUserAvailability] = useState("");
    const [userColor, setUserColor] = useState("crm-color-green");

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [designation, setDesignation] = useState("");
    const [platform, setPlatform] = useState("");
    const [site, setSite] = useState("");
    const [description, setDescription] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");

    const url = window.url;
    const url2 = window.url2;
    const qry = new FormData();

    const fullDate = new Date();

    useEffect(() => {
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);
            // console.log(userData);

            qry.append("emp_id", userData.user_id);

        }
        addUserColumnsApi();
    }, []);


    const addUserColumnsApi = () => {
        axios.post(url + "fetch-add-user-columns.php", qry).then((response) => {
            if (response.data.status == 1) {
                setAddUserColumns(response.data);
            }

            // console.log(response.data);
        }).catch(error => {
            console.log("error");
        });
    }


    const addUserSubmit = (e) => {
        e.preventDefault();

        setLoadingToggle(0);
        // alert("Successful");

        // console.log(name+phone+email+designation+platform+site+description+username);

        if (password.length < 6) {
            setAlertMsg("Password must be at least 6 digit");
            setalert(1);
        }
        else if (password != rePassword) {
            setAlertMsg("New Password and Re Password must be same");
            setalert(1);
        }
        else {
            setLoadingToggle(1);
            setAlertMsg("");
            setalert(0);

            const addUserData = new FormData();
            addUserData.append("emp_id", userDetails.user_id);
            addUserData.append("username", username);
            addUserData.append("name", name);
            addUserData.append("phone", phone);
            addUserData.append("email", email);
            addUserData.append("designation", designation);
            addUserData.append("description", description);
            addUserData.append("password", password);

            axios.post(url + "add-new-user.php", addUserData).then(response => {

                // console.log(response.data);
                if (response.data.status == 1) {
                    setLoadingToggle(0);
                    setAlertMessage(response.data.msg);
                    setAlertColor("crm-color-green");
                    setAlertToggle(1);
                    props.reload();

                    setTimeout(() => {
                        props.close();
                    }, 500);
                }
            }).catch(error => {
                console.log(error);
            })
        }

    }

    const checkUser = (e) => {
        e.preventDefault();

        setUserAvailability("");
        setAvailability(0);
        setDisable(0);

        qry.append("username", e.target.value);

        var info;

        if (e.target.value.length >= 10) {
            axios.post(url + "check-user.php", qry).then((response) => {
                if (response.data.status == 1) {
                    setAvailability(1);
                    setUserAvailability(response.data.msg);
                    setUserColor("crm-color-green");
                    setDisable(1);
                }
                else {
                    setAvailability(1);
                    setUserAvailability(response.data.msg);
                    setUserColor("crm-color-red");
                    setDisable(0);
                }
                // console.log(response.data);
            }).catch(error => {
                console.log("error");
            });
        }
        else {
            setAvailability(1);
            setUserAvailability("username must be at least 10 letters");
            setUserColor("crm-color-red");
            setDisable(0);
        }
        setUsername(e.target.value);
    }

    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>

                    {loadingToggle == 1 ?
                        <PopUpLoader />
                        : <></>
                    }

                    {alertToggle == 1 ?
                        <div className='crm-alert'>
                            <p className={alertColor}> {alertMessage} </p>
                        </div>
                        : <></>
                    }

                    <div className='add-new-head'>
                        <h2> Add New User <i className='fa fa-user-plus'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form onSubmit={addUserSubmit}>
                            <label> Username </label>
                            <br />
                            <input type='text' className='form-input' value={username} onChange={(e) => checkUser(e)} placeholder='username' required />
                            {availability ?
                                <>
                                    <p className={userColor}> {userAvailablity} </p>
                                </>
                                : <></>
                            }

                            <label> Name* </label>
                            <br />
                            <input type='text' className='form-input' value={name} onChange={(e) => setName(e.target.value)} placeholder='name' disabled={disable == 0 ? "disabled" : ""} required />

                            <label> Phone* </label>
                            <br />
                            <input type='number' className='form-input' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='phone' disabled={disable == 0 ? "disabled" : ""} required />

                            <label> Email </label>
                            <br />
                            <input type='email' className='form-input' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='email' disabled={disable == 0 ? "disabled" : ""} required />

                            <label> Password </label>
                            <br />
                            <input type='password' className='form-input' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='password' disabled={disable == 0 ? "disabled" : ""} required />

                            <label> Re-Enter Password </label>
                            <br />
                            <input type='password' className='form-input' value={rePassword} onChange={(e) => setRePassword(e.target.value)} placeholder='Re-enter Password' disabled={disable == 0 ? "disabled" : ""} required />

                            <select required value={designation} onChange={(e) => setDesignation(e.target.value)} disabled={disable == 0 ? "disabled" : ""}>
                                <option value=""> Designation </option>

                                {addUserColumns != [] && addUserColumns.designation_data != null ?
                                    addUserColumns.designation_data.map(data => {
                                        return (<>
                                            <option value={data.designation_code}> {data.designation} </option>
                                        </>)
                                    })
                                    : <></>
                                }
                            </select>


                            <label> Description </label>
                            <br />
                            <textarea name="description" placeholder='description (optional)' value={description} onChange={(e) => setDescription(e.target.value)} disabled={disable == 0 ? "disabled" : ""}></textarea>

                            {alert ?
                                <>
                                    <br />
                                    <p className={alertColor}> {alertMsg} </p>
                                </>
                                : <></>
                            }

                            <button type='submit' className='crm-btn-primary'> Add User </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddUserPopup;