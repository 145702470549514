import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from './components/Header';
import EditLeadDetailsPopup from './components/EditLeadDetailsPopup';
import AddResponsePopup from './components/AddResponsePopup';
import axios from 'axios';
import VisitDonePopup from './components/VisitDonePopup';
import FullPageLoader from './components/FullPageLoader';

const ViewLeadDetails = () => {

    const [clientDetails, setClientDetails] = useState([]);
    const [clientResponses, setClientResponses] = useState([]);
    const [clientRequirement, setClientRequirement] = useState([]);
    const [requirementHistory, setRequirementHistory] = useState([]);
    const [userDetails, setUserDetails] = useState([]);

    const [toggleEditLeadPopup, setToggleEditLeadPopup] = useState(0);
    const [toggleResponsePopup, setToggleResponsePopup] = useState(0);
    const [toggleVisitDonePopup, setToggleVisitDonePopup] = useState(0);

    const [pageLoader, setPageLoader] = useState(0);
    const [designation, setDesignation] = useState();

    const navigate = useNavigate();
    const { leadId, visit } = useParams();


    const url2 = window.url2;
    const qry = new FormData();

    useEffect(() => {
        setPageLoader(1);
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setUserDetails(userData);

            // console.log(userData);

        }
        fetchLeadDetailssApi();
    }, []);


    const fetchLeadDetailssApi = () => {
        setPageLoader(1);
        qry.append("lead_id", leadId);

        axios.post(url2 + "fetch-lead-details.php", qry).then((response) => {
            if (response.data.status == 1) {
                setClientDetails(response.data.details);
                setClientResponses(response.data.responses);
                setClientRequirement(response.data.client_requirement);
                setRequirementHistory(response.data.requirement_history);
                console.log(response.data);
            }
            setPageLoader(0);
        }).catch(error => {
            console.log(error.message);

            if (error.message == "Network Error") {
                fetchLeadDetailssApi();
            }
        });
    }


    const closeEditLeadPopup = () => {
        setToggleEditLeadPopup(0);
    }

    const closeAddResponsePopup = () => {
        setToggleResponsePopup(0);
    }

    const closeVisitDonePopup = () => {
        setToggleVisitDonePopup(0);
    }



    const inventoryFoundApi = () => {
        var confirm = window.confirm("Are you sure ?");
        // alert(confirm);

        if (confirm == 1) {
            setPageLoader(1);
            qry.append("lead_id", leadId);
            qry.append("emp_id", "");

            axios.post(url2 + "set-found-inventory.php", qry).then((response) => {
                if (response.data.status == 1) {
                    alert("Done");
                    fetchLeadDetailssApi();
                    // console.log(response.data);
                }
                else{
                    alert("something went wrong!")
                }
                setPageLoader(0);
            }).catch(error => {
                console.log(error.message);

                if (error.message == "Network Error") {
                    fetchLeadDetailssApi();
                }
            });
        }
    }
    return (
        <>
            <Header />

            {toggleEditLeadPopup === 1 ?
                <EditLeadDetailsPopup reload={fetchLeadDetailssApi} leadId={clientDetails.id} close={closeEditLeadPopup} />
                : <></>
            }

            {toggleResponsePopup === 1 ?
                <AddResponsePopup reload={fetchLeadDetailssApi} leadId={leadId} visit={visit} close={closeAddResponsePopup} />
                : <></>
            }

            {toggleVisitDonePopup === 1 ?
                <VisitDonePopup reload={fetchLeadDetailssApi} leadId={leadId} visit={visit} close={closeVisitDonePopup} />
                : <></>
            }

            {pageLoader == 1 ?
                <FullPageLoader />
                : <></>
            }

            <div className='container-head'>
                <div style={{ "width": "30%" }}>
                    <h2> {clientDetails.name} </h2>
                </div>

                <div>
                    <button onClick={() => { setToggleResponsePopup(1) }} className='crm-btn-primary'> Add Response <i className='fa fa-reply'></i> </button>
                </div>

            </div>
            <div className='crm-container'>

                <div className='view-lead-page'>
                    <div className='cards-flex'>
                        <div className='lead-details-card'>

                            <div className='table-item-right'>
                                <div>
                                    <h2>{clientDetails.name} </h2>
                                </div>
                                <div>
                                    <a href={"tel:+91" + clientDetails.phone}><i className='fa fa-phone'></i> +91 {clientDetails.phone} </a>
                                    <a href={"https://api.whatsapp.com/send?phone=91" + clientDetails.phone + "&text=hello"} target='blank'><i className='fa fa-whatsapp'></i> </a>
                                </div>
                                <div>
                                    <p> {clientDetails.email} </p>
                                </div>
                                <div>
                                    <p> Lead Date {clientDetails.lead_date} </p>
                                </div>
                                <div className='lead-details-card-right'>

                                    {/* {
                                        clientDetails.clientResponses.visiting_date != "" ?
                                        
                                            {// <h3> Visit On {data.visiting_date}</h3>}
                                            :
                                            <h3> Follow Up</h3>
                                    }    */}

                                    <h3 className='crm-color-primary'>{clientDetails.process_status == 1 ? "New" : clientDetails.process_status == 2 ? "New" : clientDetails.process_status == 3 ? "Follow Up" : clientDetails.process_status == 4 ? "R2V " + clientDetails.r2v_date + " / " + clientDetails.r2v_time : clientDetails.process_status == 5 ? "Visited On: " + clientDetails.visited_date : clientDetails.process_status == 6 ? "Closed" : clientDetails.process_status == 7 ? "Payment Done" : clientDetails.process_status == 8 ? "Not Interested" : ""}</h3>
                                </div>

                                <div>
                                    {clientDetails.process_status == 4 ? <button onClick={() => { setToggleVisitDonePopup(1) }} className='crm-btn-primary'><i className='fa fa-check'></i> Visit Done </button>
                                        : <></>}
                                </div>

                                <div className='requirement-message'>
                                    <p>Message: {clientDetails.message} </p>
                                </div>
                            </div>

                            <div className='lead-for'>
                                <span>Lead for: {clientDetails.site_name}</span>
                            </div>
                        </div>

                        <div className='lead-details-card'>
                            <div className='div'>
                                <div className='lead-details-card-right'>
                                    <h2> Location :</h2> <p> {clientRequirement != [] ? clientRequirement.location : "N/A"}</p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Property Type :</h2> <p> {clientRequirement != [] ? clientRequirement.property_type : "N/A"} </p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Size :</h2> <p> {clientRequirement != [] ? clientRequirement.size : "N/A"} </p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Client Category :</h2> <p> {clientRequirement != [] ? clientRequirement.client_category : "N/A"} </p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Budget ₹ :</h2> <p> {clientRequirement != [] ? clientRequirement.budget : "N/A"} </p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> </h2> <i onClick={() => setToggleEditLeadPopup(1)} className='fa fa-edit edit'></i>
                                </div>

                                <div className='requirement-message'>
                                    <p>Message: {clientRequirement.message} </p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <div>
                                        {clientDetails.inventory_status == 0 ? <button onClick={() => inventoryFoundApi()} className='crm-btn-primary'><i className='fa fa-check'></i> Inv Found </button>
                                            : <></>}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className='follow-up-section'>

                        <h2 className='h2'> <i className='fa fa-headphones'></i> Follow Up</h2>

                        <div>

                            {clientResponses != [] || clientResponses != "" ?
                                clientResponses.map(data => {
                                    return (
                                        <>
                                            <div className='follow-up-card'>
                                                <div>
                                                    <h2> <i className='fa fa-clock-o'></i> {data.call_date} | {data.call_time} </h2>
                                                    <h2> Next Follow Up : {data.next_follow_date} | {data.next_follow_time} </h2>
                                                </div>

                                                <div className='lead-response'>
                                                    <p> {data.response} </p>

                                                    {
                                                        data.visiting_date != "" ?
                                                            <h3 className='crm-color-green'> Visiting On {data.visiting_date}</h3>
                                                            :
                                                            <h3 className='crm-color-primary'> Follow Up</h3>
                                                    }
                                                </div>
                                                <span className={data.visiting_date != "" ? 'response-tag crm-bg-green' : 'response-tag'}> {data.tag} </span>
                                            </div>
                                        </>
                                    )
                                })
                                : <>
                                    <div className='follow-up-card'>Not Now </div>
                                </>

                            }

                            {clientDetails.process_status == 5 ?
                                <>
                                    <h2 className='crm-color-green' style={{ "font-size": "20px", "float": "right", "padding-bottom": "20px" }}> Visit Done on: {clientDetails.visited_date}</h2>
                                    <br />
                                    <p>Visit Messaage: {clientDetails.visited_response}</p>
                                </>
                                : <></>
                            }


                        </div>
                    </div>

                </div>

            </div>
        </>


    );
}

export default ViewLeadDetails;