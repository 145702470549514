import react, { useEffect, useState } from 'react';
import PopUpLoader from './PopUpLoader';
import axios from 'axios';

const AddResponseTagPopup = (props) => {
    const [userDetails, setUserDetails] = useState([]);
    const [loadingToggle, setLoadingToggle] = useState(0);
    const [alertToggle, setAlertToggle] = useState(0);
    const [alertMessage, setAlertMessage] = useState("Lead successfully added");
    const [alertColor, setAlertColor] = useState("crm-color-red");

    const [alert, setalert] = useState(0);
    const [alertMsg, setAlertMsg] = useState("");
    const [disable, setDisable] = useState(0);


    const [availability, setAvailability] = useState(0);
    const [tagAvailability, setTagAvailability] = useState("");
    const [TagColor, setTagColor] = useState("crm-color-green");

    const [description, setDescription] = useState("");
    const [tagName, setTagName] = useState("");

    const url = window.url;
    const url2 = window.url2;
    const qry = new FormData();

    const fullDate = new Date();

    useEffect(() => {
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);
            // console.log(userData);

            qry.append("emp_id", userData.user_id);

        }
    }, []);

    const addTagSubmit = (e) => {
        e.preventDefault();

    
        setLoadingToggle(0);
        // alert("Successful");

        // console.log(name+phone+email+designation+platform+site+description+tagName);

        if (disable != 0) {
            setLoadingToggle(1);
            setAlertMsg("");
            setalert(0);

            const addTagData = new FormData();
            addTagData.append("emp_id", userDetails.user_id);
            addTagData.append("tag_name", tagName);
            // addTagData.append("description", description);

            axios.post(url + "add-new-tag.php", addTagData).then(response => {

                // console.log(response.data);
                if (response.data.status == 1) {
                    setLoadingToggle(0);
                    setAlertMessage(response.data.msg);
                    setAlertColor("crm-color-green");
                    setAlertToggle(1);
                    props.reload();

                    setTimeout(() => {
                        props.close();
                    }, 500);
                }
            }).catch(error => {
                console.log(error);
            })
        }

    }

    const checkTag = (e) => {
        e.preventDefault();

        setTagAvailability("");
        setAvailability(0);
        setDisable(0);

        qry.append("tag_name", e.target.value);

        var info;

        if (e.target.value.length > 0) {
            axios.post(url + "check-tag.php", qry).then((response) => {
                if (response.data.status == 1) {
                    setAvailability(1);
                    setTagAvailability(response.data.msg);
                    setTagColor("crm-color-green");
                    setDisable(1);
                }
                else {
                    setAvailability(1);
                    setTagAvailability(response.data.msg);
                    setTagColor("crm-color-red");
                    setDisable(0);
                }
                // console.log(response.data);
            }).catch(error => {
                console.log("error");
            });
        }
        else {
            setAvailability(1);
            setTagAvailability("");
            setDisable(0);
        }
       
        setTagName(e.target.value);
    }

    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>

                    {loadingToggle == 1 ?
                        <PopUpLoader />
                        : <></>
                    }

                    {alertToggle == 1 ?
                        <div className='crm-alert'>
                            <p className={alertColor}> {alertMessage} </p>
                        </div>
                        : <></>
                    }

                    <div className='add-new-head'>
                        <h2> Add New Tag <i className='fa fa-tag'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form onSubmit={addTagSubmit}>
                            <label> Tag Name </label>
                            <br />
                            <input type='text' className='form-input' value={tagName} onChange={(e) => checkTag(e)} placeholder='tag_name' required />
                            {availability ?
                                <>
                                    <p className={TagColor}> {tagAvailability} </p>
                                </>
                                : <></>
                            }

                            {/* <label> Description </label>
                            <br />
                            <textarea name="description" placeholder='description (optional)' value={description} onChange={(e) => setDescription(e.target.value)} disabled={disable == 0 ? "disabled" : ""}></textarea> */}

                            {alert ?
                                <>
                                    <br />
                                    <p className={alertColor}> {alertMsg} </p>
                                </>
                                : <></>
                            }

                            <button type='submit' className='crm-btn-primary'> Add Tag </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddResponseTagPopup;