import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Brand from './../img/brand.svg'
import AddLeadPopup from './../components/AddLeadPopup';

const Header = () => {

    const [state, setState] = useState(1);
    const [nav, setNav] = useState("fa fa-bars");
    const [toggleLeadPopup, setToggleLeadPopup] = useState(0);


    const [userDetails, setUserDetails] = useState([]);
    const [designation, setDesignation] = useState();

    const location = useLocation();

    const navigate = useNavigate();

    // console.log(location.pathname);


    useEffect(() => {
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);
            setDesignation(userData.designation);
            // console.log(userData);
        }
        else {
            navigate("/login");
        }

        // localStorage.clear();
    }, []);

    const drawer = (status) => {
        document.querySelector('.sidebar').style.left = status === 1 ? 'calc(100% - 250px)' : 'calc(100% - 45px)';
        // document.querySelector('.header-right i.fa-bars').style.display = status === 1? 'none' : 'block';
        // document.querySelector('.header-right i.fa-power-off').style.display = status === 1? 'block' : 'none';
        if (state === 1) {
            setState(0);
            setNav("fa fa-times");
        }
        else if (state === 0) {
            setState(1);
            setNav("fa fa-bars");
        }
    }

    const logout = () => {
        localStorage.clear();
        navigate("/login");
    }


    const closeLeadPopup = () => {
        setToggleLeadPopup(0);
    }
    const reload = () => {

    }

    return (
        <>

            {toggleLeadPopup === 1 ?
                <AddLeadPopup reload={reload} close={closeLeadPopup} />
                : <></>
            }
            <div className='header'>
                <h2 className='crm'> CRM </h2>

                <div className='header-right'>
                    <h2 className='designation'> {userDetails.name} </h2>
                    <i className='fa fa-power-off visible-hide logout' onClick={() => logout()}></i>
                    <i className={nav + ' hide-visible'} onClick={() => { drawer(state) }}></i>
                </div>
            </div>
            <div className='sidebar'>
                <img className='brand visible-hide' src={Brand} alt="swarnbhoomii-logo" />

                <ul>
                    <li className={location.pathname === '/' ? 'side-link active' : 'side-link'}> <Link to='/' > <i className='fa fa-tachometer'></i>Dashboard </Link></li>
                    <li className={location.pathname === '/all-users' ? 'side-link active' : 'side-link'}> <Link to='/all-users' > <i className='fa fa-user'></i>All Users </Link></li>
                    <li className={location.pathname === '/all-leads' ? 'side-link active' : 'side-link'}> <Link to='/all-leads' > <i className='fa fa-users'></i>All Leads </Link></li>
                    <li className={location.pathname === '/new-leads' ? 'side-link active' : 'side-link'}> <Link to='/new-leads' > <i className='fa fa-list'></i>New Leads </Link></li>
                    <li className={location.pathname === '/new-visits' ? 'side-link active' : 'side-link'}> <Link to='/new-visits' > <i className='fa fa-eye'></i>New Visits </Link></li>
                    <li className={location.pathname === '/response-tags' ? 'side-link active' : 'side-link'}> <Link to='/response-tags' > <i className='fa fa-tag'></i>Response Tags </Link></li>
                    <li className={location.pathname === '/all-source' ? 'side-link active' : 'side-link'}> <Link to='/all-source' > <i className='fa fa-vcard'></i>All Source </Link></li>
                    <li className='side-link'> <button onClick={() => logout()}> <i className='fa fa-power-off'></i>Log Out </button></li>
                </ul>
            </div>
        </>
    );
}

export default Header;