import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import AssignToVisit from './components/AssignToVisit';
import axios from 'axios';
import TableLoader from './components/TableLoader';

const NewVisits = () => {

    const [toggleAssignLeadPopup, setToggleAssignLeadPopup] = useState(0);
    const [filterAd, setfilterAd] = useState("");
    const [adNames, setAdNames] = useState([]);
    const [assigningData, setAssigingData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [tableLoader, setTableLoader] = useState(0);

    const [filterData, setFilterData] = useState([]);

    const url2 = window.url2;
    const qry = new FormData();

    const [check, setCheck] = useState(0);
    const navigate = useNavigate();

    const checkbox = useRef();

    useEffect(() => {
        setTableLoader(1);
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);

            qry.append("emp_id", userData.user_id);
        }

        fetchLeadsApi();
    }, []);

    const reload = () => {
        qry.append("emp_id", userDetails.user_id);
        fetchLeadsApi();
    }
    const fetchLeadsApi = () => {
        setTableLoader(1);
        axios.post(url2 + "fetch-new-visits-for-assigning.php", qry).then((response) => {
            if (response.data.status == 1) {
                setClientData(response.data.data);
                setFilterData(response.data.data);
                setAdNames(response.data.ad_name);
                // console.log(response.data);
            } else {
                setClientData([]);
                setFilterData([]);
            }
            setTableLoader(0);
        }).catch(error => {
            console.log("error");
        });
    }

    const toggleChecked = () => {
        if (check == 0) {
            setCheck(1);
            // document.querySelector(".checkbox");
        }
        else {
            setCheck(0);
        }
    }

    const closeAssignLeadPopup = () => {
        setToggleAssignLeadPopup(0);
    }

    var i = 0;
    const index = () => {
        i = i + 1;
    }

    const addToAssign = (e, id) => {
        if (e == true) {
            if (assigningData.length != 0) {

                var exist = 0;
                assigningData.find((item) => {
                    if (item == id) {
                        exist = 1;
                    }
                });
                if (exist != 1) {
                    setAssigingData([...assigningData, id]);
                }

            }
            else {
                setAssigingData([...assigningData, id]);
            }
        }
        else if (e == false) {
            if (assigningData.length != 0) {

                assigningData.findIndex((value, item) => {
                    if (value == id) {

                        for (let i = item; i < assigningData.length - 1; i++) {
                            assigningData[i] = assigningData[i + 1];
                        }
                        assigningData.length = assigningData.length - 1;
                    }
                });
            }
        }
        // console.log(e);
        // alert("successful");
        // console.log(assigningData);
    }
    return (
        <>

            {toggleAssignLeadPopup === 1 ?
                <AssignToVisit data={assigningData} emp_id={userDetails.user_id} reload={reload} close={closeAssignLeadPopup} />
                : <></>
            }

            <Header />

            <div className='container-head'>
                <div>
                    <h2> New Visits </h2>

                    <div className='filter'>

                        <select onChange={(e) => setfilterAd(e.target.value)}>
                            <option value=""> Filter Ad</option>
                            {
                                adNames.map((data) => {
                                    return (
                                        <>
                                            <option value={data.id}> {data.ad_name} </option>
                                        </>
                                    );
                                })
                            }
                        </select>
                    </div>
                </div>

                <div>
                    &nbsp;
                    <button className='crm-btn-green' onClick={() => setToggleAssignLeadPopup(1)}> Assign <i className='fa fa-forward'></i></button>
                </div>

            </div>

            <div className='crm-container'>
                {tableLoader == 1 ?

                    <TableLoader />
                    :
                    <>
                        <div className='crm-table visible-hide'>

                            <table>
                                <thead>
                                    <tr> <th> <input type='checkbox' onChange={() => toggleChecked()} /> S No</th> <th> Date </th> <th> Name </th> <th> Phone </th> <th> Email </th> <th> Action </th></tr>
                                </thead>

                                <tbody>
                                    {
                                        filterData.length != 0 ?
                                            <>
                                                {filterAd != "" ?
                                                    filterData.filter((item) => item.ad_name == filterAd).map((data) => {
                                                        index();
                                                        return (
                                                            <tr><td> <input type='checkbox' onChange={(e) => addToAssign(e.target.checked, data.id)} className='checkbox' /> {i}</td> <td>{data.lead_date}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.email}</td>  <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td></tr>
                                                        );
                                                    })
                                                    : filterData.map((data) => {
                                                        index();
                                                        return (
                                                            <tr><td> <input type='checkbox' onChange={(e) => addToAssign(e.target.checked, data.id)} className='checkbox' /> {i}</td> <td>{data.lead_date}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.email}</td>  <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td></tr>
                                                        );
                                                    })
                                                }
                                            </>
                                            : <><tr><td>Empty</td></tr></>
                                    }
                                </tbody>
                            </table>
                        </div>


                        <div className='crm-phone-table hide-visible'>
                            <p style={{ "display": "none" }}>{i = 0}</p>

                            {
                                filterData.length != 0 ?
                                    <>
                                        {filterAd != "" ?
                                            filterData.filter((item) => item.ad_name == filterAd).map((data) => {
                                                index();
                                                return (
                                                    <>
                                                        <div className='phone-table-item'>
                                                            <div className='table-item-left'>
                                                                <input type='checkbox' onChange={(e) => addToAssign(e.target.checked, data.id)} className='checkbox' />
                                                                <h2> {i} </h2>
                                                            </div>
                                                            <div className='table-item-right'>
                                                                <div>
                                                                    <h2>{data.name}</h2>
                                                                    <h2>+91 {data.phone} </h2>
                                                                </div>
                                                                <div>
                                                                    <p> {data.email} </p>
                                                                </div>
                                                                <div>
                                                                    <p> {data.lead_date} </p>
                                                                    <Link to={'/view-lead-details/' + data.id}>View </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                            : filterData.map((data) => {
                                                index();
                                                return (
                                                    <>
                                                        <div className='phone-table-item'>
                                                            <div className='table-item-left'>
                                                                <input type='checkbox' onChange={(e) => addToAssign(e.target.checked, data.id)} className='checkbox' />
                                                                <h2> {i} </h2>
                                                            </div>
                                                            <div className='table-item-right'>
                                                                <div>
                                                                    <h2>{data.name}</h2>
                                                                    <h2>+91 {data.phone} </h2>
                                                                </div>
                                                                <div>
                                                                    <p> {data.email} </p>
                                                                </div>
                                                                <div>
                                                                    <p> {data.lead_date} </p>
                                                                    <Link to={'/view-lead-details/' + data.id}>View </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                    : <><tr><td>Empty</td></tr></>
                            }


                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default NewVisits;