import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import AddUserPopup from './components/AddUserPopup';
import TableLoader from './components/TableLoader';
import axios from 'axios';

const AllUsers = () => {

    const [toggleUserPopup, setToggleUserPopup] = useState(0);
    const [filterDesignation, setFilterDesignation] = useState("");
    const [designationsData, setDesignationsData] = useState([]);
    const [assigningData, setAssigingData] = useState([]);
    const [usersData, setUsersData] = useState([]);

    const [userDetails, setUserDetails] = useState([]);
    const [tableLoader, setTableLoader] = useState(0);

    const [filterData, setFilterData] = useState([]);

    const url = window.url;
    const qry = new FormData();

    const [check, setCheck] = useState(0);
    const navigate = useNavigate();

    const checkbox = useRef();

    useEffect(() => {
        setTableLoader(1);
        if (localStorage.getItem("crm-admin")) {
            const userData = JSON.parse(localStorage.getItem("crm-admin"));
            setUserDetails(userData);

            if (userData.designation == "A") {

            }
            else {
                navigate("/");
            }
            qry.append("emp_id", userData.user_id);
        }

        fetchUsersApi();
    }, []);

    const reload = () => {
        qry.append("emp_id", userDetails.user_id);
        fetchUsersApi();
    }

    const fetchUsersApi = () => {
        setTableLoader(1);
        axios.post(url + "fetch-all-users.php", qry).then((response) => {
            if (response.data.status == 1) {
                setUsersData(response.data.data);
                setFilterData(response.data.data);
                setDesignationsData(response.data.designations);
            }
            // console.log(response.data);
            setTableLoader(0);
        }).catch(error => {
            console.log("error");
        });
    }



    const closeUserPopup = () => {
        setToggleUserPopup(0);
    }


    var i = 0;
    const index = () => {
        i = i + 1;
    }

    const toggleStatus = (id,e) => {

        var confirm = window.confirm("Are you sure ?");
        if (confirm == 1) {
            setTableLoader(1);
            qry.append("emp_id", userDetails.user_id);
            qry.append("user_id", id);
            qry.append("status",e);
            axios.post(url + "change-user-status.php", qry).then((response) => {
                if (response.data.status == 1) {
                    reload();
                }
                alert(response.data.msg);
                // console.log(response.data);
                setTableLoader(0);
            }).catch(error => {
                console.log("error");
            });
        }
        else{
            setTableLoader(0);
        }
    }
    return (
        <>

            {toggleUserPopup === 1 ?
                <AddUserPopup reload={reload} close={closeUserPopup} />
                : <></>
            }

            <Header />

            <div className='container-head'>
                <div>
                    <h2> All Users </h2>

                    <div className='filter'>

                        <select onChange={(e) => setFilterDesignation(e.target.value)}>
                            <option value=""> Designation</option>
                            {
                                designationsData.map((data) => {
                                    return (
                                        <>
                                            <option value={data.designation_code}> {data.designation} </option>
                                        </>
                                    );
                                })
                            }
                        </select>
                    </div>

                </div>

                <div>

                    {userDetails.designation == "A" ?
                        <button className='crm-btn-primary' onClick={() => { setToggleUserPopup(1) }}>  New <i className='fa fa-user-plus'></i> </button>
                        : <></>
                    }
                </div>

            </div>

            <div className='crm-container'>
                {tableLoader == 1 ?

                    <TableLoader />
                    :
                    <>
                        <div className='crm-table visible-hide'>

                            <table>
                                <thead>
                                    <tr> <th> S No</th> <th> Name </th> <th> Phone </th> <th> Email </th> <th> Designatnion </th> <th> Status </th> {/*</tr>th> Action </th> */} </tr>
                                </thead>

                                <tbody>
                                    {
                                        filterData.length != 0 ?
                                            <>
                                                {filterDesignation != "" ?
                                                    filterData.filter((item) => item.designation_code == filterDesignation).map((data) => {
                                                        index();
                                                        return (
                                                            <tr><td> {i}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.email}</td> <td> {data.designation} </td> <td> <button onClick={() => toggleStatus(data.id,data.status == 1 ?"0":data.status == 0?"1":"")} className={data.status == 1 ? "crm-btn-green" : data.status == 0 ? "crm-btn-red" : ""}>{data.status == 1 ? "Active" : data.status == 0 ? "InActive" : ""}</button> </td>  {/*<td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td> */} </tr>
                                                        );
                                                    })
                                                    : filterData.map((data) => {
                                                        index();
                                                        return (
                                                            <tr><td> {i}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.email}</td> <td> {data.designation} </td> <td> <button onClick={() => toggleStatus(data.id,data.status == 1 ?"0":data.status == 0?"1":"")} className={data.status == 1 ? "crm-btn-green" : data.status == 0 ? "crm-btn-red" : ""}>{data.status == 1 ? "Active" : data.status == 0 ? "InActive" : ""}</button> </td>  {/* <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td> */} </tr>
                                                        );
                                                    })
                                                }
                                            </>
                                            : <><tr><td>Empty</td></tr></>
                                    }
                                </tbody>
                            </table>
                        </div>


                        <div className='crm-phone-table hide-visible'>
                            <p style={{ "display": "none" }}>{i = 0}</p>

                            {
                                filterData.length != 0 ?
                                    <>
                                        {filterDesignation != "" ?
                                            filterData.filter((item) => item.designation_code == filterDesignation).map((data) => {
                                                index();
                                                return (
                                                    <>
                                                        <div className='phone-table-item'>
                                                            <div className='table-item-left'>
                                                                <h2> {i} </h2>
                                                            </div>
                                                            <div className='table-item-right'>
                                                                <div>
                                                                    <h2>{data.name}</h2>
                                                                    <h2>+91 {data.phone} </h2>
                                                                </div>
                                                                <div>
                                                                    <p> {data.email} </p>
                                                                </div>
                                                                <div>
                                                                    <p> {data.designation} </p>
                                                                    {/* <Link to={'/view-lead-details/' + data.id}>View </Link> */}
                                                                </div>
                                                                <div>
                                                                    <button onClick={() => toggleStatus(data.id,data.status == 1 ?"0":data.status == 0?"1":"")} className={data.status == 1 ? "crm-btn-green" : data.status == 0 ? "crm-btn-red" : ""}>{data.status == 1 ? "Active" : data.status == 0 ? "InActive" : ""}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                            : filterData.map((data) => {
                                                index();
                                                return (
                                                    <>
                                                        <div className='phone-table-item'>
                                                            <div className='table-item-left'>
                                                                <h2> {i} </h2>
                                                            </div>
                                                            <div className='table-item-right'>
                                                                <div>
                                                                    <h2>{data.name}</h2>
                                                                    <h2>+91 {data.phone} </h2>
                                                                </div>
                                                                <div>
                                                                    <p> {data.email} </p>
                                                                </div>
                                                                <div>
                                                                    <p> {data.designation} </p>
                                                                    {/* <Link to={'/view-lead-details/' + data.id}>View </Link> */}
                                                                </div>
                                                                <div>
                                                                <button onClick={() => toggleStatus(data.id,data.status == 1 ?"0":data.status == 0?"1":"")} className={data.status == 1 ? "crm-btn-green" : data.status == 0 ? "crm-btn-red" : ""}>{data.status == 1 ? "Active" : data.status == 0 ? "InActive" : ""}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                    : <><tr><td>Empty</td></tr></>
                            }


                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default AllUsers;